import axios from "service/axios";
import { default as Axios } from "axios";
import Web3 from "web3";
// utils
import { poll } from "./utils";
// abi
import koiRouterABI from "./abi/KoiRouter.json";
import koiTokenABI from "./abi/KoiToken.json";
// sdk
import Arweave from "arweave";
import { interactWrite as interactWriteSdk } from "smartweave";
// assets
import cryptofishList from "./json/cryptofish.json";

const arweave = Arweave.init({
  host: "arweave.net",
  protocol: "https",
  port: 443,
});
export const sleep = (t = 300) => new Promise(resolve => setTimeout(resolve, t));

export const initExtension = async () => {
  try {
    // Does it exist?
    let extensionObj = await poll(() => window.koiiWallet, 1000, 200);
    // Is it connected?
    let res = await extensionObj.getPermissions();

    if (res.status === 200 && res.data.length) return true;
    else return false;
  } catch (error) {
    // Have to throw error to trigger rejected
    throw new Error("Extension does not exist");
  }
};

export const connectToExtension = async () => {
  try {
    const extension = window.koiiWallet;
    let res = await extension.connect();
    if (res.status === 200) return true;

    throw new Error(res?.data);
  } catch (error) {
    throw new Error(error);
  }
};

export const getAddress = async () => {
  const extension = window.koiiWallet;
  try {
    let res = await extension.getAddress();
    if (res) return res;
    else throw new Error(res.data);
  } catch (error) {
    // If we get here it's most likey user uninstalled extension and re-installed
    // Very edgy edge-case, but better to handle than not
    extension?.disconnect();
    throw new Error(error);
  }
};

/* Axios Opensea URLs */

export const getOpenseaAssets = async ethAddress => {
  return axios.get(
    `https://api.opensea.io/api/v1/assets?owner=${ethAddress}&order_direction=desc&offset=0&limit=50&asset_contract_address=${process.env.REACT_APP_ETH_CONTRACT}`,
    /* OpenSea does not like Access-Control-Allow-Origin */
    {
      transformRequest: (data, headers) => {
        delete headers.common["Access-Control-Allow-Origin"];
        return data;
      },
    }
  );
};

const getOpenseaUrls = () => {
  return [...Array(1)].map((_, i) => {
    return `https://api.opensea.io/api/v1/assets?offset=${
      i === 0 ? 0 : i * 50
    }&limit=50&asset_contract_address=${process.env.REACT_APP_ETH_CONTRACT}`;
  });
};

export const getAllSoldOpenseaAssets = async () => {
  const urls = getOpenseaUrls();
  const fetchURLs = (url, delay) =>
    new Promise(resolve => setTimeout(resolve, delay)).then(() =>
      axios.get(url, {
        transformRequest: (data, headers) => {
          delete headers.common["Access-Control-Allow-Origin"];
          return data;
        },
      })
    );
  const promiseArray = urls.map((url, i) => fetchURLs(url, i * 1000));
  return await Promise.allSettled(promiseArray).then(res => {
    const totalArray = res.reduce(
      (prev, next) => [...prev, ...(next?.value?.data?.assets || [])],
      []
    );
    const uniqueArray = totalArray.filter(
      (v, i, a) => a.findIndex(t => t.token_id === v.token_id) === i
    );

    return uniqueArray;
  });
};

export const getAllSoldOpenseaAssetsFirst = async () => {
  return Axios.get(
    `https://deep-index.moralis.io/api/v2/nft/${process.env.REACT_APP_ETH_CONTRACT}?chain=eth&format=decimal&order=DESC`,
    /* OpenSea does not like Access-Control-Allow-Origin */
    {
      headers: {
        "X-API-Key": "7KPdCcNGx5SStFE2JrPvTjnggNjQffRWqmXismfGIQwymgqNRdj9j7IL6owV1l4C",
      },
    }
  ).then(res => res?.data?.result);
};

export const getSoldOpenseaAssets = async () => {
  return axios.get(
    `https://api.opensea.io/api/v1/assets?offset=0&limit=50&asset_contract_address=${process.env.REACT_APP_ETH_CONTRACT}`,
    /* OpenSea does not like Access-Control-Allow-Origin */
    {
      transformRequest: (data, headers) => {
        delete headers.common["Access-Control-Allow-Origin"];
        return data;
      },
    }
  );
};

export const connectToEthWalletAndGetZombies = async () => {
  if (typeof window.ethereum !== "undefined") {
    /* MetaMask is installed */
    let nfts = [];
    let ethAddress = null;
    await window.ethereum.request({ method: "eth_requestAccounts" }).then(async ethAddrArr => {
      if (!ethAddrArr || !ethAddrArr[0]) {
        throw new Error("no_accounts");
      }
      ethAddress = ethAddrArr[0];
      return await getOpenseaAssets(ethAddrArr[0]).then(async res => {
        const zombiesNfts = res?.data?.assets;
        if (zombiesNfts.length === 0) {
          throw new Error("no_nfts_found");
        } else {
          nfts = zombiesNfts;
        }
      });
    });
    return { nfts, ethAddress };
  } else {
    /* MetaMask is not installed */
    throw new Error("extension_not_installed");
  }
};

export const connectToEthWallet = async () => {
  // await sleep(1000);
  if (typeof window.ethereum !== "undefined") {
    /* MetaMask is installed */
    let ethAddress = null;
    await window.ethereum.request({ method: "eth_requestAccounts" }).then(async ethAddrArr => {
      if (!ethAddrArr || !ethAddrArr[0]) {
        throw new Error("no_accounts");
      }
      ethAddress = ethAddrArr[0];
    });
    return { ethAddress };
  } else {
    /* MetaMask is not installed */
    throw new Error("extension_not_installed");
  }
};

const KOI_ROUTER_CONTRACT = "0x5b8db3177f9904b4f8510d7fd074a44abf610528";

export const handleEthereumCall = async (id, userWallet) => {
  console.log({ userWallet });
  /* Bridging */
  const ethContract = process.env.REACT_APP_ETH_CONTRACT;

  let web3;
  if (typeof window.web3 !== undefined) {
    web3 = new Web3(window.web3.currentProvider);
  } else {
    web3 = new Web3("https://mainnet.infura.io/v3/f811f2257c4a4cceba5ab9044a1f03d2");
  }
  let contract = new web3.eth.Contract(koiRouterABI, KOI_ROUTER_CONTRACT);

  let addresses = await web3.eth.getAccounts();
  console.log("Addresses: ", addresses);
  if (!addresses[0]) {
    throw new Error("No Metamask wallet found");
  }

  // this is setApproval, works only for koitoken but it should be generic
  let nftContract = new web3.eth.Contract(koiTokenABI, ethContract);

  console.log("Checking if approved...");

  const isApprove = await nftContract.methods
    .isApprovedForAll(addresses[0], KOI_ROUTER_CONTRACT)
    .call();

  console.log("IS APPROVED?", isApprove);
  if (isApprove === false) {
    console.log("NOT APPROVED");
    const NonApprovedResults = await nftContract.methods
      .setApprovalForAll(KOI_ROUTER_CONTRACT, true)
      .send({ from: addresses[0] });
    console.log("non-Approved receipt: ", NonApprovedResults);
  }
  console.log("YES APPROVED");

  const ApprovedResults = await contract.methods
    .deposit(ethContract, id, 1, userWallet)
    .send({ from: addresses[0], value: web3.utils.toWei("0.00015", "ether") });
  console.log("Approved receipt: ", ApprovedResults);
};

export const handleBuyEthereumCall = async selectedToBuy => {
  const ethContract = process.env.REACT_APP_ETH_CONTRACT;
  console.log("Buying...", { selectedToBuy, ethContract });
  let web3;
  if (typeof window.web3 !== undefined) {
    web3 = new Web3(window.web3.currentProvider);
  } else {
    web3 = new Web3("https://mainnet.infura.io/v3/f811f2257c4a4cceba5ab9044a1f03d2");
  }
  web3.eth.handleRevert = true;
  let nftContract = new web3.eth.Contract(koiTokenABI, ethContract);

  let addresses = await web3.eth.getAccounts();
  console.log(addresses);
  if (!addresses[0]) {
    throw new Error("No Metamask wallet found");
  }

  /* Check if this nft is already sold or not! */
  try {
    await nftContract.methods.create(addresses[0], 1, "", selectedToBuy, "0x").estimateGas({
      from: addresses[0],
      value: web3.utils.toWei("0.03", "ether"),
    });
  } catch (e) {
    let err = e;
    err = err + "";
    let error = err.substring(0, 41);
    const isNftSold = error === "Error: execution reverted: Already exists";
    if (isNftSold) {
      /* Throw nft is sold error */
      throw new Error("nft_sold");
    } else {
      /* Throw generic error */
      // throw new Error("");
    }
  }

  return await nftContract.methods.create(addresses[0], 1, "", selectedToBuy, "0x").send({
    from: addresses[0],
    value: web3.utils.toWei("0.03", "ether"),
  });
};

export const getRandomNftId = async () => {
  return await axios.get(`/cryptofish/getUnassignedNFT`);
};
export const postRandomNftId = async walletAddress => {
  return await axios.post(`/cryptofish/getUnassignedNFT`, {
    walletAddress,
  });
};

export const getRandomAssignedNftId = async () => {
  return await axios.get(`/cryptofish/getRandomAssignedNFT`);
};

export const getNFTByUserWallet = async walletAddress => {
  return await axios.post(`/cryptofish/getNFTByUserWallet`, {
    walletAddress,
  });
};

export const buyNft = async (nftId, receiverAddress, transferTx) => {
  return await axios.post(`/cryptofish/buyNft`, {
    nftId,
    receiverAddress, // address from wallet.
    transferKOIITx: transferTx, // transfer tx id
  });
};

export const getTransferStatus = async ethNftId => {
  return await axios.post(`/fetchBridgeDetails`, {
    flow: "EthereumToArweave",
    ethereumNFTId: ethNftId,
  });
};

export const getDataForArweaveNFTId = async nftId => {
  return await axios.post(`/getDataForArweaveNFTId`, {
    nftId: nftId,
  });
};

export const interactWrite = async (input, koiContract) => {
  return await interactWriteSdk(
    arweave,
    null,
    koiContract,
    input,
    null,
    null,
    null,
    1.7 * 102370790
  );
};

/* Leaderboard */
const getLatestFromMainnet = async () => {
  return await axios.get("https://mainnet.koii.live/attention/latest", {
    transformRequest: (data, headers) => {
      delete headers.common["Access-Control-Allow-Origin"];
      return data;
    },
  });
};

export async function getZombies() {
  let nfts = [];
  let zombies = [];
  let zKeys = Object.keys(cryptofishList);
  await getLatestFromMainnet().then(res => {
    nfts = res?.data;
  });
  /* Add this filter below if we need to hide nfts with 0 viewers */
  // zKeys = zKeys.filter(k => nfts[k]);
  zKeys.sort((a, b) => (nfts[b] || 0) - (nfts[a] || 0));
  for (const i of zKeys) {
    zombies.push({
      id: i,
      name: cryptofishList[i].name,
      description: cryptofishList[i].description,
      views: nfts[i] || 0,
    });
  }

  return zombies;
}

export const generateCardWithData = async nft => {
  console.log({ nft });

  return await Axios.post(`https://api.koii.live/generateCardWithData`, {
    data: {
      id: nft?.id,
      title: nft?.name,
      description: nft?.description,
      contentType: "text/html",
    },
    media: {
      url: "htmltype",
    },
  });
};
