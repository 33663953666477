import { Link as RouterLink } from "react-router-dom";
import {
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Center,
  Text,
  Stack,
  Button,
  Link,
} from "@chakra-ui/react";
// icons
import { RiCloseFill } from "react-icons/ri";
// assets
import { ReactComponent as Logo } from "assets/images/brand/logo.svg";

type PageDrawerProps = {
  goToStep: (step: any) => any;
  isOpen: boolean;
  onClose: () => void;
};

export const PageDrawer: React.FC<PageDrawerProps> = ({ goToStep, isOpen, onClose }) => {
  return (
    <>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent
          bg={{ base: "rgba(40, 142, 244, 0.7)", lg: "rgba(40, 142, 244, 0.7)" }}
          boxShadow="0px 0px 12px 6px rgba(119, 209, 146, 0.3)"
          pt={{ base: "8", lg: "14" }}
          color="white"
        >
          <DrawerCloseButton top="15px" size="lg">
            <Center flexDir="column">
              <RiCloseFill size="25px" />
              <Text as="span" fontSize="10px">
                close
              </Text>
            </Center>
          </DrawerCloseButton>
          <DrawerBody>
            <Stack
              pb={{ base: "4", lg: "20" }}
              h="100%"
              spacing="24px"
              sx={{
                "& a": {
                  boxShadow: "none",
                  _focus: {
                    boxShadow: "none",
                  },
                  _hover: {
                    textDecor: "underline",
                  },
                },
                "& button": {
                  textTransform: "none",
                  border: "none",
                  fontWeight: 400,
                  padding: "0",
                  textAlign: "left",
                  height: "auto",
                  _focus: {
                    boxShadow: "none",
                  },
                  _hover: {
                    textDecor: "underline",
                  },
                },
              }}
            >
              {/* <Link href="https://blog.koii.network/atomiczombies/" isExternal>
                What are Atomic CryptoFish?
              </Link> */}
              <Button variant="unstyled" onClick={() => goToStep(4)}>
                Buy a CryptoFish with KOII
              </Button>
              {/* <Button variant="unstyled" onClick={() => goToStep(2)}>
                Buy a CryptoFish with ETH
              </Button> */}
              <RouterLink to="/leaderboard">CryptoFish Attention Leaderboard</RouterLink>
              <Link target="_blank" href="https://twitter.com/maiagin">Follow our Artist: Varfegul </Link>

              {/* <RouterLink to="/graveyard">
                <span>Sneak through the Graveyard</span>
                <Text as="span" d="block" fontSize="12px" color="rgba(121, 210, 148, 1)">
                  See other Arweave CryptoFishes
                </Text>
              </RouterLink> */}
              <Link href="https://koii.network/getFinnie" isExternal>
                Get the Finnie Wallet
              </Link>
              {/* <Link href="https://atomiczombies.io/" isExternal>
                Check Atomic Zombies
              </Link> */}
              {/* <Link href="https://opensea.io/collection/atomic-zombies" isExternal>
                Opensea Collection
              </Link> */}

              <Link href="https://koii.network" isExternal mt="auto!important">
                <Center justifyContent="flex-start">
                  <Text as="span" mr="18px">
                    Powered by Koii
                  </Text>
                  <Logo />
                </Center>
              </Link>
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};
