import React from "react";

/* Context */
import { useZombie } from "components/contexts/zombie";
// chakra
import {
  Button,
  Stack,
  Text,
  Flex,
  Heading,
  useDisclosure,
  Box,
  Link,
  Icon,
  Center,
  Tooltip,
} from "@chakra-ui/react";
import { PageBackButton } from "components/atoms";
// ui
import { PageLayout } from "components/layouts";
import { ShareModal } from "components/modals";
// icons
import { RiExternalLinkLine } from "react-icons/ri";
import { getTransferStatus } from "shared/api";

type SceneProps = {
  goToStep: (step: number) => any;
};

type State = {
  transferTx: any;
  randomId: any;
  nftType: any;
  selectedToRaise: any;
};

export const Six: React.FC<SceneProps> = ({ goToStep }) => {
  /* Hooks */
  const { isOpen, onOpen, onClose } = useDisclosure();

  /* Context */
  const {
    state: { randomId, nftType, selectedToRaise, transferTx },
  }: { state: State } = useZombie();

  const [nftId, setNftId] = React.useState(randomId);

  const isRaised = nftType === "raised" ? true : false;
  const [isDoneRaising, setIsDoneRaising] = React.useState<boolean>(false);

  const [ethTxId, setEthTxId] = React.useState();
  React.useEffect(() => {
    let intervalFetch: any;
    if (isRaised) {
      intervalFetch = setInterval(async () => {
        await getTransferStatus(selectedToRaise)
          .then(res => {
            const tx = res?.data?.data?.[0];
            if (tx) {
              if (tx?.isBridged) {
                setNftId(tx?.arNFTId);
                setEthTxId(tx?.burnTxId);
                setIsDoneRaising(true);
                clearInterval(intervalFetch);
              }
            }
          })
          .catch(() => {
            setIsDoneRaising(false);
          });
      }, 5000);
    }

    return () => clearInterval(intervalFetch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRaised]);

  return (
    <>
      {/* Confirm Modal */}
      <ShareModal id={nftId} isOpen={isOpen} onClose={onClose} />
      <PageLayout scene="6">
        <PageBackButton onClick={() => goToStep(1)} />
        <Flex
          alignItems={{ base: "flex-start", lg: "center" }}
          flexDir="column"
          // pr={{ base: "4", lg: "300px" }}
          pl="4"
          pt={{ base: "12", lg: "20" }}
          color="white"
        >
          <Box>
            <Heading fontWeight="400" pt="20px" fontSize={{ base: "1xl", md: "6xl", lg: "30px" }}>
              Your Cryptofish is on the way...
            </Heading>
            <Text
              fontFamily="pixel"
              fontSize={{ base: "1xl", lg: "20px" }}
              mt={{ base: "4px", lg: "10x" }}
            >
              A CryptoFish is heading for you
            </Text>
            <Text fontSize="15px" mt="9px" maxW="500px" textShadow="1px 1px 2px rgba(0, 0, 0, 1)" fontFamily="pixel">
              Your CryptoFish will arrive in your wallet in 2 hours. Show your friends your catch and earn attention!
            </Text>
            {(!isRaised || (isRaised && isDoneRaising)) && (
              <>
                <Stack align="center" direction="row" spacing="0" mt="18px">
                  <Text
                    fontSize="15px"
                    wordBreak="break-all"
                    textShadow="1px 1px 2px rgba(0, 0, 0, 1)"
                    fontFamily="pixel"
                  >
                    Arweave NFT ID:
                  </Text>{" "}
                  <Link
                    d="flex"
                    alignItems="center"
                    isExternal
                    href={`https://viewblock.io/arweave/tx/${nftId}`}
                    color="rgb(255, 153, 36);"
                    pl="2"
                    fontFamily="pixel"
                  >
                    <Text as="span" d="inline-block">
                      <Text
                        as="span"
                        noOfLines={1}
                        maxW={{ base: "150px", lg: "200px" }}
                        textShadow="0px 2px 4px rgba(0, 0, 0, 0.25)"
                        wordBreak="break-all"
                        fontFamily="pixel"
                      >
                        {nftId}
                      </Text>{" "}
                    </Text>
                    <Icon ml="2" as={RiExternalLinkLine} />{" "}
                  </Link>
                </Stack>
                {isRaised && isDoneRaising && (
                  <Stack align="center" direction="row" spacing="0" mt="18px">
                    <Text
                      fontSize="15px"
                      wordBreak="break-all"
                      textShadow="1px 1px 2px rgba(0, 0, 0, 1)"
                      fontFamily="pixel"
                    >
                      Payment Tx ID:
                    </Text>{" "}
                    <Link
                      d="flex"
                      alignItems="center"
                      isExternal
                      href={`https://etherscan.io/tx/${ethTxId}`}
                      color="rgb(255, 153, 36);"
                      pl="2"
                      fontFamily="pixel"
                    >
                      <Text as="span" d="inline-block">
                        <Text
                          as="span"
                          noOfLines={1}
                          maxW={{ base: "150px", lg: "200px" }}
                          textShadow="0px 2px 4px rgba(0, 0, 0, 0.25)"
                          wordBreak="break-all"
                          fontFamily="pixel"
                        >
                          {ethTxId}
                        </Text>{" "}
                      </Text>
                      <Icon ml="2" as={RiExternalLinkLine} />{" "}
                    </Link>
                  </Stack>
                )}
                {!isRaised && (
                  <Stack align="center" direction="row" spacing="0" mt="18px">
                    <Text
                      fontSize="15px"
                      wordBreak="break-all"
                      textShadow="1px 1px 2px rgba(0, 0, 0, 1)"
                      fontFamily="pixel"
                    >
                      Payment Tx ID:
                    </Text>{" "}
                    <Link
                      d="flex"
                      alignItems="center"
                      isExternal
                      href={`https://viewblock.io/arweave/tx/${transferTx}`}
                      color="rgb(255, 153, 36);"
                      pl="2"
                      fontFamily="pixel"
                    >
                      <Text as="span" d="inline-block">
                        <Text
                          as="span"
                          noOfLines={1}
                          maxW={{ base: "150px", lg: "200px" }}
                          textShadow="0px 2px 4px rgba(0, 0, 0, 0.25)"
                          wordBreak="break-all"
                        >
                          {transferTx}
                        </Text>{" "}
                      </Text>
                      <Icon ml="2" as={RiExternalLinkLine} />{" "}
                    </Link>
                  </Stack>
                )}
              </>
            )}
            <Stack
              spacing="4"
              direction={{ base: "column", lg: "row" }}
              mt="22px"
              w="100%"
              maxW="520px"
            >
              
              <Tooltip
                isDisabled={!isRaised}
                placement="right-start"
                bg="purple.500"
                maxW="200px"
                fontSize="12px"
                arrowShadowColor="white"
                hasArrow
                p="2"
                label="Sharing will be enabled after about 3 minutes, once the transfer process has begun."
              >
                <Button
                  h="56px"
                  color="blue.300"
                  size="lg"
                  w={{ base: "100%", lg: "100%" }}
                  fontSize="16px"
                  onClick={!isRaised ? onOpen : isDoneRaising ? onOpen : () => {}}
                  fontFamily="pixel"
                >
                  <Center flexDir="column">
                    <span>Share for Attention Rewards</span>
                  </Center>
                </Button>
              </Tooltip>
            </Stack>
          </Box>
        </Flex>
      </PageLayout>
    </>
  );
};
