import React from "react";
// chakra
import { Button, Text, Box, Heading, Center, useToast } from "@chakra-ui/react";
// ui
import { PageLayout } from "components/layouts";
import { PageBackButton } from "components/atoms";
import { connectToEthWallet, connectToEthWalletAndGetZombies, getAddress } from "shared/api";
import { useZombie } from "components/contexts/zombie";

type SceneProps = {
  goToStep: (step: number) => any;
};

export const Two: React.FC<SceneProps> = ({ goToStep }) => {
  const { dispatch }: { dispatch: (args: any) => void } = useZombie();

  /* Hooks */
  const toast = useToast();

  const [isLoading, setIsLoading] = React.useState(false);
  /* Raise */
  const doConnectToEthAndRaise = async () => {
    setIsLoading(true);
    await connectToEthWalletAndGetZombies()
      .then(async res => {
        await getAddress()
          .then(addressRes => {
            if (addressRes.status === 200) {
              /* We're connected to finnie, raise */
              dispatch({
                type: "CHANGE_FIELDS",
                payload: {
                  ethAddress: res?.ethAddress,
                  address: addressRes?.data,
                  zombiesNfts: res?.nfts,
                  step: "raise",
                },
              });
            } else {
              /* We're not connected, go to step 3 */
              dispatch({
                type: "CHANGE_FIELDS",
                payload: {
                  ethAddress: res?.ethAddress,
                  zombiesNfts: res?.nfts,
                  step: 3,
                },
              });
            }
          })
          .catch(() => {
            /* We're not connected, go to step 3 */
            dispatch({
              type: "CHANGE_FIELDS",
              payload: {
                ethAddress: res?.ethAddress,
                zombiesNfts: res?.nfts,
                step: 3,
              },
            });
          });
      })
      .catch(err => {
        if (err?.message === "extension_not_installed") {
          toast({ status: "error", title: "You don't have MetaMask installed", isClosable: true });
        } else if (err?.message === "no_accounts") {
          toast({
            status: "error",
            title: "Please add an account your MetaMask",
            isClosable: true,
          });
        } else if (err?.message === "no_nfts_found") {
          toast({
            status: "error",
            title: <>There aren't any CryptoFish in this wallet.</>,
            isClosable: true,
          });
        } else {
          toast({
            status: "error",
            title: "There was an error connecting MetaMask. Please try again.",
            isClosable: true,
          });
        }
        setIsLoading(false);
      });
  };

  /* Buy */
  const doConnectToEthAndBuy = async () => {
    setIsLoading(true);
    await connectToEthWallet()
      .then(async res => {
        setIsLoading(false);
        /* Connected, Go to buy */
        dispatch({
          type: "CHANGE_FIELDS",
          payload: {
            ethAddress: res?.ethAddress,
            step: "buy-eth",
          },
        });
      })
      .catch(err => {
        setIsLoading(false);
        if (err?.message === "extension_not_installed") {
          toast({ status: "error", title: "You don't have MetaMask installed", isClosable: true });
        } else if (err?.message === "no_accounts") {
          toast({
            status: "error",
            title: "Please add an account your MetaMask",
            isClosable: true,
          });
        } else {
          toast({
            status: "error",
            title: "There was an error connecting MetaMask. Please try again.",
            isClosable: true,
          });
        }
      });
  };

  return (
    <>
      <PageLayout scene="3">
        <PageBackButton onClick={() => goToStep(1)} />
        <Box
         pr="4" pl={{ base: "6", lg: "96" }} pt={{ base: "14", lg: "40" }} color="white" ml={{ base:"0", lg: "225px"}}
        >
          <Heading 
          fontWeight="400"
          fontSize={{ base: "5xl", md: "6xl", lg: "75px" }}
          lineHeight="1"
          fontFamily="pixel"
          textShadow='0px 0px 20px #ffffff,1px 1px #000000 , 0px 0px 10px #ffffff'
          >
            <Text as="span" color="blue.500" fontSize={{ base: "5xl", md: "6xl", lg: "90px" }}>
              Fishing: <Box as="br" d={{ base: "block", lg: "none" }} />
            </Text>{" "}
            CryptoFish
          </Heading>
          <Text
            fontSize="30px"
            mt={{ base: "3", lg: "10" }}
            maxW="525px"
            className="text--shadowed"
            fontFamily="pixel"
            letterSpacing= "-0.03em;"
            textShadow='0px 0px 20px #ffffff, 0px 0px 10px #ffffff'
          >
           Like fishing? Fish can also be stored in your Finnie wallet as CryptoFish. Get more attention to unlock hidden styles
            <Box as="span" d="block" h="10px" />
            <Box as="span" d="block" h="10px" />
            <Text as="span" color="blue.500" textShadow='0px 0px 20px #ffffff, 0px 0px 10px #ffffff'>
              1 CryptoFish = 0.03 ETH.
            </Text>
          </Text>
          <div>
            <Button
              color="#00E5DF"
              border="0px solid"
              background="#0084BC"
              _hover={{ bg: '#2f5a9a' }}
              shadow="0px 0px 15px 10px #00bcca"
              size="lg"
              fontSize="16px"
              w="270px"
              onClick={doConnectToEthAndBuy}
            >
              <Center flexDir="column">
                <span>Get your CryptoFish</span>
                <Text as="span" color="green.500" mt="2px" fontSize="12px">
                  Purchase with ETH
                </Text>
              </Center>
            </Button>
            <Text
              pl={{ base: "0", lg: "0" }}
              pt="15px"
              fontSize="12px"
              mt="2"
              lineHeight="1.3"
              textShadow="0px 2px 4px rgba(0, 0, 0, 0.25)"
              maxW="400px"
            >
              Already trapped your CryptoFish in an ETH wallet?{" "}
              <Text
                as="span"
                d="block"
                role="button"
                textDecor="underline"
                cursor="pointer"
                onClick={doConnectToEthAndRaise}
              >
                Skip ahead to raise from the dead.
              </Text>
            </Text>
          </div>
        </Box>
      </PageLayout>
    </>
  );
};
