import React from "react";
// chakra
import { Fade, ScaleFade } from "@chakra-ui/react";
import { One, Two, Three, Four, Six, Eight, Raise } from "./scenes";
import { useZombie } from "components/contexts/zombie";

type State = {
  step: any;
};

const Home = () => {
  const {
    state: { step },
    dispatch,
  }: { state: State; dispatch: (args: any) => void } = useZombie();

  function goToStep(step: any) {
    dispatch({ type: "GO_TO_STEP", payload: step });
  }

  function renderScene(step: any) {
    switch (step) {
      case 1:
        return (
          <>
            <One goToStep={goToStep} />
          </>
        );
      case 2:
        return (
          <ScaleFade in initialScale={0.98}>
            <Two goToStep={goToStep} />
          </ScaleFade>
        );
      case 3:
        return (
          <Fade in>
            <Three goToStep={goToStep} />
          </Fade>
        );
      case 4:
        return (
          <ScaleFade in initialScale={0.98}>
            <Four goToStep={goToStep} />
          </ScaleFade>
        );
      case 6:
        return (
          <ScaleFade in initialScale={0.98}>
            <Six goToStep={goToStep} />
          </ScaleFade>
        );
      case 8:
        return (
          <ScaleFade in initialScale={0.98}>
            <Eight goToStep={goToStep} />
          </ScaleFade>
        );
      // case 9:
      //   return (
      //     <Fade in>
      //       <Nine goToStep={goToStep} />
      //     </Fade>
      //   );
      case "raise":
        return (
          <Fade in>
            <Raise goToStep={goToStep} />
          </Fade>
        );
      case "buy-eth":
        return (
          <Fade in>
            {/* <BuyEth goToStep={goToStep} /> */}
          </Fade>
        );
      default:
        return <One goToStep={goToStep} />;
    }
  }
  return <>{renderScene(step)}</>;
};
export default Home;
