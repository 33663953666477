import React from "react";
import { Link as RouterLink } from "react-router-dom";
import LazyLoad from "react-lazyload";
// chakra
import {
  Heading,
  Stack,
  Text,
  Image,
  SimpleGrid,
  Center,
  Spinner,
  Box,
  Skeleton,
  Link,
  Button,
} from "@chakra-ui/react";
// ui
import { PageLayout } from "components/layouts";
import { NftLeaderboardBox, PageBackButton } from "components/atoms";
// icons
import { RiExternalLinkLine } from "react-icons/ri";
// apis
import { generateCardWithData, getZombies } from "shared/api";
// assets
import crown from "assets/images/crown.png";
import border from "assets/images/border.png"
import { zeroPad } from "shared/utils";

export const Leaderboard = () => {
  /* Hooks */
  const [{ nfts, nftsView, status }, setNfts] = React.useState({
    nfts: [],
    status: "loading",
  });

  const [selected, setSelected] = React.useState();

  const selectedNft = nfts?.filter(n => n.id === selected)?.[0];

  const getNfts = async () => {
    let arr = [];
    try {
      /* Get Sold Nfts on Opensea */
      await getZombies().then(res => {
        arr = res;
      });
      setNfts(prevState => ({
        ...prevState,
        nfts: arr,
        nftsView: arr,
        status: "success",
      }));
      setSelected(arr?.[0]?.id);
    } catch (err) {
      setNfts(prevState => ({ ...prevState, status: "error" }));
    }
  };

  React.useEffect(() => {
    /* Get Nfts on mount */
    getNfts();
  }, []);

  const isCrownSelected = selectedNft?.id === nfts?.[0]?.id;

  let formattedName;
  if (selectedNft?.name) {
    let formattedText = selectedNft?.name?.replaceAll?.("Atomic", "");
    formattedText = formattedText?.replaceAll?.("Zombie", "");
    formattedName = formattedText || selectedNft?.id;
  } else {
    formattedName = `#${zeroPad(selectedNft?.id)}`;
  }

  return (
    <>
      <PageLayout
        scene="10"
        h={{ base: "unset", lg: "100vh" }}
        minH={{ base: "100vh", lg: "unset" }}
      >
        <PageBackButton as={RouterLink} to="/" />
        {status !== "success" && (
          <Center pt="5" w="100vw" h="100vh">
            <Stack
              px="4"
              py="4"
              flexDir="column"
              maxW="300px"
              bg="#4442FF"
              color="white"
              border="3px solid #FF9924"
              rounded="md"
              w="100%"
              mx="auto"
              alignItems="center"
              textAlign="center"
            >
              <Text fontSize="lg" fontWeight="500">
                {status === "loading"
                  ? "Getting CryptoFish..."
                  : "An error occurred getting your CryptoFish, Please refresh to try again!"}
              </Text>
              {status === "loading" && (
                <Spinner
                  size="lg"
                  thickness="4px"
                  speed="0.65s"
                  emptyColor="violet.100"
                  color="white"
                />
              )}
            </Stack>
          </Center>
        )}
        {status === "success" && (
          <SimpleGrid
            maxW="1200px"
            w="100%"
            mx="auto"
            columns={{ base: 1, lg: 2 }}
            px={{ base: "4", lg: "8" }}
            py={{ base: "10", lg: "20" }}
            color="white"
          >
            {/* Selection */}
            <Stack
              w="100%"
              spacing="4"
              maxW="350px"
              mx="auto"
              pb={{ base: "4", lg: "0" }}
              textAlign="center"
              justifyContent="center"
            >
              <Heading 
              fontWeight="400"
              fontSize={{ base: "2xl" , lg: "20px"}}
              fontFamily="pixel"
              >
                <Text color="white" 
                fontSize={{ base: "5xl" , lg: "60px"}}
                textShadow="-3px -3px 0 #FF9924,
                0px -3px 0 #FF9924,
                3px -3px 0 #FF9924,
                -3px 3px 0 #FF9924,
                0px 3px 0 #FF9924,
                3px 3px 0 #FF9924;"
                letterSpacing="-0.12em"
                fontFamily="cygnito">Crypto Fish</Text>
                Leaderboard
              </Heading>
              <Center w="100%" py="4" pos="relative">
                {/* Selected Nft Image
                {isCrownSelected && (
                  <Image src={crown} alt="crown" pos="absolute" top="-7px" right="18px" />
                )} */}
                <Image
                class="topimage"
                  src={`https://koii.live/${selectedNft?.id}.png`}
                  mx="auto"
                  maxH="100%"
                  boxSize="225px"
                  borderRadius="full"
                  border={isCrownSelected ? "7.5px solid #FF9924" : "3px solid #FF9924"}
                //   sx={{ "border-image-source": `url(${border})`,
                //   "border-image-slice": "30;",
                //  " border-image-repeat": "stretch;",
                //   "border-image-slice": "30;",
                //   "border-image-width": "20px;",
                //   "border-image-outset": "10px;" }}
                  
                />
              </Center>
              {/* Selected Nft Details */}

              <Stack spacing="1">
                <Text
                  fontWeight="600"
                  fontSize="2xl"
                  noOfLines={1}
                  textShadow="1px 1px 2px rgba(0, 0, 0, 1)"
                  fontFamily="pixel"
                >
                  {formattedName}
                </Text>

                <Text fontSize="xs" textShadow="1px 1px 2px rgba(0, 0, 0, 1)">
                  {selectedNft?.description}
                </Text>
                <Text as="span" h="20px" />
                <Stack
                  rounded="sm"
                  px="8"
                  pt="2"
                  pb="1px"
                  w="130px"
                  spacing="0px"
                  mx="auto!important"
                  border="3px solid #FF9924"
                  fontWeight="600"
                  textAlign="center"
                  backgroundColor="#4442FF"
                >
                  <Text fontSize="25px" lineHeight="1">
                    {selectedNft?.views}
                  </Text>
                  <Text color="#05FFD1">Views</Text>
                </Stack>
                {/* <Stack mx="auto" w="100%" pt="2">
                  <Button
                    as={Link}
                    variant="outline"
                    isExternal
                    href={`https://viewblock.io/arweave/tx/${selectedNft?.id}`}
                    fontWeight="400"
                    bg="transparent"
                    w="125px"
                    mx="auto"
                    _hover={{ bg: "transparent" }}
                    textTransform="unset"
                    fontSize="13px"
                    px="2"
                    _active={{ bg: "transparent" }}
                    iconSpacing="1"
                    leftIcon={<RiExternalLinkLine color="#05FFD1" size="24px" />}
                  >
                    Explore Block
                  </Button>
                </Stack> */}
              </Stack>
            </Stack>

            {/* Nfts Grid */}
            <Box>
              <SimpleGrid
                w="100%"
                maxW={{ base: "350px", lg: "800px" }}
                maxH={{ base: "400px", lg: "635px" }}
                columns={{ base: 2, md: 3, lg: 5 }}
                className="custom-scroll"
                id="leaderboard--scroll"
                pos="relative"
                overflowY="auto"
                gap="2"
                mx="auto"
                p="4px"
              >
                {nftsView?.map((nft, i) => (
                  <LazyLoad
                    height="150px"
                    scrollContainer="leaderboard--scroll"
                    overflow
                    key={nft?.id || i}
                    debounce={500}
                    placeholder={
                      <Skeleton
                        startColor="violet.500"
                        endColor="violet.400"
                        speed={2}
                        w="100px"
                        h="150px"
                        rounded="sm"
                      />
                    }
                  >
                    <NftLeaderboardBox
                      onClick={() => setSelected(nft.id)}
                      isActive={selected === nft.id}
                      currentNft={nft}
                      index={i}
                    />
                  </LazyLoad>
                ))}
              </SimpleGrid>
            </Box>
          </SimpleGrid>
        )}
      </PageLayout>
    </>
  );
};
