export const Button = {
  baseStyle: {
    borderRadius: "20px",
    borderStyle: "solid",
    borderColor: "#FF9924",
    borderWidth: "2px",
    lineHeight: "16px",
    letterSpacing: "0.015em",
    fontFamily: "PT Mono, monospace",
    _loading: {
      opacity: 1,
    },
  },
  defaultProps: {
    colorScheme: "blue",
  },
  variants: {
    white: {
      bg: "white",
      color: "black",
    },
  },
  sizes: {
    sm: {
      fontSize: "PT Mono, monospace",
      px: "20px",
      py: 3,
      minW: "46px",
      h: "46px",
    },
    md: {
      fontSize: "md",
      px: 4,
      py: 2,
      minW: 14,
    },
    lg: {
      fontSize: "lg",
      px: 8,
      py: 6,
      minW: 16,
      h: 16,
    },
  },
};
