import { extendTheme } from "@chakra-ui/react";
// components
import { Button, colors } from "./theme";
// Let's say you want to add custom colors
const chakraTheme = extendTheme({
  // Colors
  colors,
  fonts: {
    body: "Epilogue, sans-serif",
    display: "Antic Slab, sans-serif",
    heading: "'Press Start 2P', cursive",
    pixel: "'Press Start 2P', cursive",
    cygnito: "Cygnito Mono, monospace",
  },
  components: {
    Button,
    // Form
    Input: {
      variants: {
        outline: {
          field: {
            borderRadius: "2px",
            borderWidth: "1px",
            borderColor: "white",
            _hover: { borderColor: "white" },
            _focus: { borderColor: "white" },
            _invalid: { boxShadow: "none" },
          },
        },
      },
    },
    Tooltip: {
      baseStyle: {
        border: "1px solid #FFFFFF",
      },
      defaultProps: {
        hasArrow: true,
      },
    },
  },
});
export default chakraTheme;
