import React from "react";
// chakra
import { Button, Stack, Text, Box, Heading, Link, Flex } from "@chakra-ui/react";
// ui
import { PageLayout } from "components/layouts";

type SceneProps = {
  goToStep: (step: number) => any;
};

export const One: React.FC<SceneProps> = ({ goToStep }) => {
  return (
    <>
      <PageLayout scene="1">
        <Flex
          flexDirection="column"
          align={{ base: "center", lg: "center" }}
          pr="3"
          pl={{ base: "6", lg: "44" }}
          pt={{ base: "28", lg: "52" }}
          color="white"
          textAlign={{ base: "center", md: "unset" }}
        >
          <Heading
            fontWeight="400"
            fontSize={{ base: "6xl" , lg: "78px"}}
            fontFamily="cygnito"
            textShadow="-3px -3px 0 #FF9924,
            0px -3px 0 #FF9924,
            3px -3px 0 #FF9924,
            -3px 3px 0 #FF9924,
            0px 3px 0 #FF9924,
            3px 3px 0 #FF9924;"
            letterSpacing="-0.12em"
          >
            CRYPTO FISH
          </Heading>
          <Text fontFamily="heading">The huge 5000 shoal of crypto fish is making waves here.</Text>
          <Box w="100%" maxW="450px">
            <Stack
              spacing={{ base: "4", lg: "8" }}
              direction={{ base: "column", lg: "row" }}
              align="center"
              mt="20px"
              w="100%"
              maxW="700px"
            >
              <Button flex={{ base: "none", lg: "1 1 100%" }} onClick={() => goToStep(4)}>
                Catch in Koii Pond
              </Button>
              |
              {/* <Button flex={{ base: "none", lg: "1 1 100%" }} onClick={() => goToStep(2)}>
                Catch in Open Sea
              </Button> */}
            </Stack>
            <Text
              pl={{ base: "0", lg: "0" }}
              fontSize="12px"
              mt="10px"
              lineHeight="1.2"
              textShadow="0px 2px 4px rgba(0, 0, 0, 0.25)"
              maxW="400px"
              sx={{
                "@media (max-width: 770px)": {
                  position: "absolute",
                  bottom: "15px",
                  left: "50%",
                  transform: "translateX(-50%)",
                },
              }}
            >
              These NFTs are revolutionary.{" "}
              <Link isExternal href="https://blog.koii.network/atomiczombies" textDecor="underline">
                Learn more.
              </Link>
            </Text>
          </Box>
        </Flex>
      </PageLayout>
    </>
  );
};
