import React from "react";
// context
import { useZombie } from "components/contexts/zombie";
// chakra
import {
  Button,
  Stack,
  Text,
  Box,
  Heading,
  Tooltip,
  useToast,
  useDisclosure,
  Link,
  Center
} from "@chakra-ui/react";
// ui
import { PageLayout } from "components/layouts";
import { PageBackButton } from "components/atoms";
import { ConfirmModal } from "components/modals";
// api
import {
  connectToExtension,
  getAddress,
  getRandomNftId,
  initExtension,
  postRandomNftId,
} from "shared/api";
// sdk
import * as kweb from "@_koi/sdk/web";

const koiSDK = new kweb.Web();

type SceneProps = {
  goToStep: (step: number) => any;
};

export const Four: React.FC<SceneProps> = ({ goToStep }) => {
  /* Hooks */
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  /* States */
  const [isLoading, setIsLoading] = React.useState(false);
  const [claimError, setClaimError] = React.useState<any>(null);

  /* Context */
  const { dispatch }: { dispatch: (args: any) => void } = useZombie();

  const NftsClaimedError = (
    <>
      The Arweave CryptoFish have all been dug up! Follow{" "}
      <Link isExternal href="https://twitter.com/KoiiNetwork" textDecor="underline">
        @KoiiNetwork
      </Link>{" "}
      on Twitter for updates about the next horde.
    </>
  );

  const UserClaimedError = <>You have already claimed your CryptoFish!</>;

  /* Buy a zombie */
  async function doConnectToFinnie() {
    try {
      /* 1: Connect to finnie */
      setIsLoading(true);
      // Check if extension exists
      await initExtension();
      // Connect to extension
      await connectToExtension();
      // Get the address
      let address: string = "";
      await getAddress().then(res => {
        address = res?.data;
        if (res.status === 200) {
          /* Done */
          dispatch({
            type: "CHANGE_FIELDS",
            payload: { address: res?.data },
          });
        } else {
          toast({
            status: "error",
            title: "There was an error getting your Finnie address. Please try again.",
            isClosable: true,
          });
          throw new Error("none");
        }
      });

      // Get Random Nft Id
      await postRandomNftId(address)
        .then(res => {
          dispatch({
            type: "CHANGE_FIELDS",
            payload: {
              randomId: res?.data?.data?.nftId,
            },
          });
        })
        .catch(err => {
          if (err?.response?.data?.message === "user_already_claimed") {
            setClaimError("user_already_claimed");
            toast({
              status: "error",
              title: UserClaimedError,
              isClosable: true,
            });
          } else {
            setClaimError("no_nfts_left");
            toast({
              status: "error",
              title: NftsClaimedError,
              isClosable: true,
            });
          }
          throw new Error("none");
        });

      /* 2: Check if he has 15 Koii & 0.00000137 AR */
      await koiSDK.setWallet(address);
      // Get Koii balance
      const koiAmount = await koiSDK.getKoiBalance();
      const arAmount = await koiSDK.getWalletBalance();
      if (koiAmount < 2 || arAmount < 0.00000137) {
        toast({
          status: "error",
          title: "You don’t have enough KOII or AR in your wallet to purchase a CryptoFish.",
          isClosable: true,
        });
        throw new Error("none");
      } else {
        /* 2.5: Open Confirmation Modal */
        onOpen();
      }
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      if (error?.message === "none") {
        return;
      } else {
        toast({
          status: "error",
          title: "There was an error connecting Finnie. Please try again.",
          isClosable: true,
        });
      }
    }
  }

  return (
    <>
      {/* Confirm Modal */}
      <ConfirmModal isOpen={isOpen} onClose={onClose} />

      <PageLayout scene="2">
        <PageBackButton onClick={() => goToStep(1)} />
        <Box pr="4" pl={{ base: "6", lg: "96" }} pt={{ base: "14", lg: "40" }} color="white" ml={{ base:"0", lg: "225px"}} >
          <Heading
            fontWeight="400"
            fontSize={{ base: "2xl", md: "35px", lg: "40px" }}
            lineHeight="1"
            fontFamily="pixel"
            // textShadow='0px 0px 20px #ffffff,1px 1px #000000 , 0px 0px 10px #ffffff'
          >
            <Text as="span" color="blue.300" fontSize={{ base: "2xl", md: "30px", lg: "40px" }} lineHeight="2">
              Fishing: <Box as="br" d={{ base: "block", lg: "none" }} />
            </Text>{" "}
            CryptoFish
          </Heading>
          <Text
            fontSize={{ base: "1xl", md: "3xl", lg: "22px" }}
            mt={{ base: "10", lg: "10" }}
            maxW="620px"
            className="text--shadowed"
            fontFamily="pixel"
            letterSpacing= "0.02em;"
            lineHeight="1.4"
            // textShadow='0px 0px 20px #ffffff, 0px 0px 10px #ffffff'
          >
           Like fishing? Finnie wallet is the crypto-aquarium forever. Feed this fish with more attention to unlock hidden styles.
            <Box as="span" d="block" h="10px" />
            <Box as="span" d="block" h="10px" />
            <Text as="span" color="blue.300" >
              1 CryptoFish = 2 KOII.
            </Text>
          </Text>
          <Stack
            spacing="2"
            direction={{ base: "column", lg: "column" }}
            mt="20px"
            w="100%"
            maxW="530px"
          >
            <Tooltip
              p="2"
              hasArrow
              isDisabled={!claimError}
              arrowShadowColor="white"
              bg="purple.500"
              label={claimError === "user_already_claimed" ? UserClaimedError : NftsClaimedError}
            >
              <Button
                color="white"
                border="2px solid #FF9924"
                background="#4442FF"
                _hover={{ bg: '#2f5a9a' }}
                // shadow="0px 0px 15px 10px #00bcca"
                size="lg"
                fontSize="16px"
                w="270px"
                isLoading={isLoading}
                onClick={claimError ? () => {} : doConnectToFinnie}
                opacity={claimError ? "0.4" : "unset"}
              >
                <Center flexDir="column">
                <span>Get your CryptoFish</span>
                <Text as="span" color="#05FFD1" mt="2px" fontSize="12px">
                  Purchase with KOII
                </Text>
              </Center>
              </Button>
            </Tooltip>
            <Text
              pl={{ base: "0", lg: "0" }}
              fontSize="12px"
              mt="5px"
              lineHeight="1.2"
              textShadow="0px 2px 4px rgba(0, 0, 0, 0.25)"
              maxW="400px"
            >
              Don't have KOII?{" "}
              <Text
                as="span"
                role="button"
                textDecor="underline"
                cursor="pointer"
                onClick={() => window.open("https://koi.rocks/faucet")}
              >
                Check out the Faucet.
              </Text>
            </Text>
          </Stack>
        </Box>
      </PageLayout>
    </>
  );
};
