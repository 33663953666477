export const colors = {
  white: {
    500: "#fff",
  },
  purple: {
    "50": "#E58AE7",
    "100": "#E075E2",
    "200": "#D64BD9",
    "300": "#C42AC7",
    "400": "#9A219D",
    "500": "#711873",
    "600": "#58135A",
    "700": "#3F0D40",
    "800": "#260827",
    "900": "#0D030E",
  },
  violet: {
    "50": "#C7ADE1",
    "100": "#BA9ADA",
    "200": "#A075CC",
    "300": "#874FBF",
    "400": "#6D3BA0",
    "500": "#542D7B",
    "600": "#422361",
    "700": "#301A47",
    "800": "#1E102D",
    "900": "#0D0712",
  },
  green: {
    "50": "#F4FBF3",
    "100": "#E7F6E5",
    "200": "#CEEDCB",
    "300": "#B6E4B0",
    "400": "#9DDB96",
    "500": "#84D27B",
    "600": "#61C555",
    "700": "#46AC3B",
    "800": "#37862E",
    "900": "#276021",
  },
  blue: {
    "50": "#DDDDFF",
    "100": "#C9C8FF",
    "200": "#A0A0FF",
    "300": "#05FFD1",
    "400": "#4F4FFE",
    "500": "#2726FE",
    "600": "#0201EB",
    "700": "#0201B3",
    "800": "#01017B",
    "900": "#010043",
  },
  yellow: {
    "50": "#FFEFDC",
    "100": "#FFE5C7",
    "200": "#FFD29E",
    "300": "#FFBF76",
    "400": "#FFAC4D",
    "500": "#FF9924",
    "600": "#EB7D00",
    "700": "#B36000",
    "800": "#7B4200",
    "900": "#432400",
  },
};
